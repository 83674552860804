import React, { useEffect } from 'react';
import { AuthContext } from '../../context/Auth'
import { useHistory } from 'react-router-dom';
import './index.scss'
import unlinkIcon from '../../image/unlink.svg'
import { FormattedMessage } from 'react-intl';

const Activation = () => {

  const { state } = React.useContext(AuthContext);
  const history = useHistory()
  useEffect(() => {
    if (!history.location.search) {
      setTimeout(() => {
        if (state.username) {
          history.push('/activation')
        }
      }, 200)
    }
  }, [state.username])

  return (
    <div className="app-page-wrapper">
      <div className="expired-page">
      <div className="expired-page-icon"><img src={unlinkIcon} alt="" /></div>
      <div className="expired-page-title"><FormattedMessage id='expired.title'/></div>
        {
          history.location.search ? (
            <div className="expired-page-text"><FormattedMessage id='expired.desc' /></div>
          ) : (
            <div className="expired-page-text">
              <FormattedMessage id='expired.please'/><a href="/login"><FormattedMessage id='login'/></a><FormattedMessage id='expired.send_email'/>
            </div>
          )
        }
      </div>
    </div>
    );
};

export default Activation
