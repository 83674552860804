export function getBrowserLanguage () {
  if (!navigator) return 'zh-CN';
  const { userLanguage, language } = navigator;
  const lang = userLanguage || language
  return lang
}
export function getIsIphone () {
  const { appVersion } = navigator;
  return appVersion.indexOf('iPhone') > -1 
}
export function getClientWidth () {
  const isIphone = getIsIphone()
  let width = 0
  if (isIphone) {
    width = window.screen.width
  } else {
    width = window.innerWidth
  }
  return width
}