import React, { useEffect, useState, useReducer } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl'
import zh_CN from './locale/zh_CN';
import en_US from './locale/en_US';
import { getBrowserLanguage } from './util'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Header from './components/PageHeader'
import routers from './router'
import { AuthContext } from './context/Auth';
import initService from './service/handleService'
import { Alert } from 'antd'
const initialState = {
    email: '',
    username: ''
};
const reducer = (state, action) => {
    switch (action.type) {
    case 'LOGIN':
        return {
            ...state,
            email: action.payload.email,
            username: action.payload.username
        };
    case 'LOGOUT':
        return {
            ...state,
            email: '',
            username: ''
        };
    default:
        return state;
    }
};
let isInitial = false;

const App = () => {
    const history = useHistory();
    const [lang, changeLang] = useState('zh')
    const [messages, changeMessage] = useState(zh_CN)
    const message = (<FormattedMessage id="maintenance_notice"></FormattedMessage>)

    const handleLocaleChange = (lang) => {
        changeLang(lang)
        localStorage.setItem('lang', lang)
        changeMessage(lang === 'zh' ? zh_CN : en_US)
    }

    useEffect(() => {
        const locallanguage = localStorage.getItem('lang')
        const defaultLanguage = getBrowserLanguage()
        const language = locallanguage || defaultLanguage
        const lang = language.includes('zh') ? 'zh' : 'en'
        handleLocaleChange(lang)
    }, [])

    const [state, dispatch] = useReducer(reducer, initialState);

    if (!isInitial) {
        initService(history, dispatch);
        isInitial = true;
    }

    return (
        <IntlProvider locale={lang} messages={messages}>
            <AuthContext.Provider value={{
                state,
                dispatch
            }}>
                <div className="App wrapper-global">
                    <Header onLocaleChange={handleLocaleChange}/>
                    {/* <Alert className="wrapper-global-alert" message={message} type="warning" showIcon /> */}
                    <Switch>
                    {
                        routers.map(router=>{
                            const Component = router.component;
                            return (
                                <Route
                                    exact
                                    path={router.path}
                                    key={router.path}
                                >
                                    <Component />
                                </Route>
                            )
                        })
                    }
                    <Route exact path="/">
                        <Redirect to="/home" /> 
                    </Route>
                    </Switch>
                </div>
        </AuthContext.Provider>
        </IntlProvider>
    );
}

export default App;
