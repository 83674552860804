import React from 'react';
import banner1 from '../../image/banner1.png'
import banner2 from '../../image/banner2.png'
import banner3 from '../../image/banner3.png'
import './index.scss'
import { FormattedMessage } from 'react-intl'

const Banner = () => {
  return (
    <div className="banner-page">
      <div className="banner-page-blank"></div>
      <div className="banner-page-inner">
        <h4 className="banner-page-inner-title">
          <FormattedMessage
            id='banner.title'
            values={{
              byzer: <span className='banner-page-inner-title-byzer'>Byzer™</span>
            }}
          />
        </h4>
        <div className="banner-page-inner-min-title">
        <FormattedMessage id='banner.desc1' />
        <span className="hightlight"><FormattedMessage id='banner.desc2' /></span><br />
        <FormattedMessage id='banner.desc3' />
        </div>
        <ul className="banner-page-inner-card">
          <li>
            <img src={banner1} alt='' />
            <div className="banner-side-card-text">
              <h3><FormattedMessage id='banner.integration' /></h3>
              <div><FormattedMessage id='banner.integration_desc1' /><br/><FormattedMessage id='banner.integration_desc2' /></div>
            </div>
          </li>
          <li>
            <img src={banner2} alt='' />
            <div className="banner-side-card-text">
              <h3><FormattedMessage id='banner.explore' /></h3>
              <div><FormattedMessage id='banner.explore_desc1' /><br/><FormattedMessage id='banner.explore_desc2' /></div>
            </div>
          </li>
          <li>
            <img src={banner3} alt='' />
            <div className="banner-side-card-text">
              <h3><FormattedMessage id='banner.learning' /></h3>
              <div><FormattedMessage id='banner.learning_desc1' /><br/><FormattedMessage id='banner.learning_desc2' /></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Banner
