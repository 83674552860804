import React, { useContext, useEffect, useState } from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../context/Auth'
import { user } from '../../service'
import SvgIcon from '../SvgIcon'
import SiteModal from '../../components/Modals/SiteModal'

const UserInfo = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { username } = state
  const history = useHistory()
  const handleLogout = async () => {
    try {
      await user.handleLogout()
      history.push('/login')
      dispatch({ type: 'LOGOUT' })
    } catch (e) { }
  }
  async function handleGetUerInfo() {
    try {
      const res = await user.getUserInfo()
      const { email, username } = res.data
      dispatch({ type: 'LOGIN', payload: { email, username } })
    } catch (e) { }
  }
  const noNeedAuthPath = ['/login', '/home', '/register', '/reset_password', '/expired', '/expired?type=reset']

  useEffect(() => {
    if (!noNeedAuthPath.includes(history.location.pathname)) {
      handleGetUerInfo()
    }
  }, [])

  const handleClick = ({ key }) => {
    if (key.startsWith('/')) {
      history.push(key)
    } else {
      handleLogout()
    }
  }
  const handleLogin = () => {
    setIsShow(true);
  }
  const closeModal = () => {
    setIsShow(false);
  }

  const handleConfirm = (site) => {
    let href = '';
    if(site === 'cn') {
      href = "https://mlsql.tech/login";
    } else {
      href = "https://byzer.org/login";
    }
    window.location.href = href;
  }

  const userIcon = 'user_22'
  const autoMenu = ( // 未登录使用
    <Menu onClick={handleClick}>
      <Menu.Item key="username" disabled>{username}</Menu.Item>
      <Menu.Item key="logout"><FormattedMessage id='logout' /></Menu.Item>
    </Menu>
  )

  const [isShow, setIsShow] = useState(false)

  return (
    <div className="user-info">
      {!username ?
        (
          <Button onClick={handleLogin} type="text"><FormattedMessage id='login' /></Button>
        ) : (<Dropdown overlay={autoMenu}>
          <span><SvgIcon iconClass={userIcon} fontSize={22} /></span>
        </Dropdown>)
      }
      <SiteModal isShow={isShow} handleConfirm={handleConfirm} handleClose={closeModal} isFromLogin={true}></SiteModal>
    </div>
  );
}

export default UserInfo;
