import React from 'react';
import RegisterForm from './Form';
import PageText from '../../components/Banner/index'
import './index.scss';

const RegisterPage = () => {
  return (
    <div className="register-page">
      <div className="register-page-text inner-box">
        <PageText />
      </div>
      <div className="register-page-form">
        <RegisterForm />
      </div>
    </div>
  );
};

export default RegisterPage
