const en_US = {
    ok: 'OK',
    cancel: 'Cancel',
    blog: 'Blog',
    tutorial: 'Documentation',
    community: 'Community',
    downloads: 'Downloads',
    video: 'Video',
    github: 'Github',
    desc: 'A low-code open-source programming language for data pipeline, analytics and AI',
    free_trial_text: 'No need to install and deploy, quickly experience Byzer online.',
    ease_of_use: 'Easy to use',
    ease_of_use_desc: `SQL for AI™️, declarative fusion imperative language, SQL-like syntax is simple and flexible. A single underlying engine supports distributed manipulation of large amounts of data and manipulation of data.`,
    data_security: 'Data Security',
    data_security_desc: `Byzer language has built-in data security and is highly scalable. Support table permissions, row-column level permissions, language-level syntax permissions, and plug-in permissions control. Scalable, easy to operate and maintain, which ensure the security of enterprise-level assets.`,
    aliyun_support: 'Cloud native support',
    aliyun_support_desc2: 'Cloud native design, Byzer engine support K8s and other cloud deployment, make full use of cloud computing power and storage. In addition, deployment modes such as Yarn are supported as well.',
    aliyun_support_desc: 'Use Byzer and analytics workspace to process your own data stored on Ali Cloud via http://analysis.Byzer.tech/',
    download: 'Downloads',
    try_online_guide: 'Try Online',
    try_online_guide_desc: 'Get yourself familiar with Byzer syntax and Analytic Workspace',
    join_wechat_group: 'Join Wechat Group',
    join_wechat_group_desc: 'Scan the QR code and send the keyword Byzer to join the wechat group.',
    load_save_1: 'Easy to Use Web IDE',
    load_save_1_desc: 'Manage your Byzer scripts/notebook in Web IDE',
    load_save_2: 'Highly scalable',
    load_save_2_desc: 'Support Python, standard library, custom UDF (Scala/Java).A large number of algorithms and feature engineering tools are built-in to help entry-level analysts pick up machine learning faster.',
    load_save_3: 'Visual ETL Workflow',
    load_save_3_desc: 'The low-code visual workflow can help the user efficiently complete the end-to-end data science journey.',
    free_trial: 'Try Online',
    'versionServer': 'Server Edition',
    'versionVSCode': 'VSCode Desktop',
    'versionWeb': 'Web IDE',
    'code-tips': 'Scan the QR code below the code, add the Byzer assistant, and the professionals can answer questions in time.',
    'Byzer_helper': 'Byzer Assistant',
    'Byzer_official': 'Byzer Official Account',
    'support_desktop_title': 'Download Byzer-lang & Byzer Notebook',
    'support_desktop_desc_pre': 'Byzer™ ',
    'support_desktop_desc': 'not only provides an online trial environment, but also provides a desktop version, a server version, and Web IDE Byzer Notebook for users to install and try locally.',
    'support_byzer_download': 'Download Byzer-lang',
    'support_desktop_download': 'Download Byzer Desktop',
    'support_byzer_notebook_download': 'Download Byzer Notebook',
    'record_information1': ' ',
    'record_information2': ' ',
    'community_title': 'About Byzer community',
    'community_text': 'Byzer is a new and dynamic open source project, the core of the community is Byzer-Lang, formerly known as the MLSQL community.',
    'community_desc1': '* For Data & AI, build technology ecosystem around Byzer',
    'community_desc2': '* Aims to unlock the productivity of analysts, engineers, and operations personnel',
    'community_desc3': '* Help users to land data platform and complete AI engineering in a cost-effective and efficient way.',
    'community_welcome': 'Welcome data developers to join and build community ecology.',
    'join_community_title': 'Welcome to Join Byzer Community on Slack',
    'join_community_text': 'Be part of our family and work together to redefine the way we build data pipelines and machine learning pipelines today.',
    'join_community_link': 'Join the Community',
    'language': 'Language',
    'login_tips': 'Please select the login site',
    'site_cn': 'CN Site',
    'site_global': 'Global Site',
    'register_tips': 'Please select the register site',
    'site_login_tip': 'Tips: The user data registered on the official website of mlsql.tech has been synchronized to the CN site',
    'login': 'Log in',
    'logout': 'Log out',
    'login.account': 'Username or email address',
    'login.account_required': 'Username or email cannot be empty',
    'forget_password': 'Forget password?',
    'account_tip': 'If you don’t have an Byzer Notebook account, please click ',
    'account_tip_register': 'register',
    'reset_password': 'Reset Password',
    'reset_password_tip': 'We will send a link to your email, and you can reset your password after clicking',
    'new_password': 'New Password',
    'password_changed': 'Your password has been changed',
    'set_password': 'Please set your new password',
    'send_email': 'Send email',
    'back_to_login': 'Back to login',
    'check_email': 'The password reset link has been sent to your email, please check the email.',
    'user.email': 'Email',
    'user.password': 'Password',
    'user.password_confirm': 'Confirm Password',
    'user.username': 'Username',
    'user.phone_number': 'Telephone',
    'user.company': 'Company Name',
    'user.email.required': 'Email cannot be empty',
    'user.password.required': 'Password cannot be empty',
    'user.password_confirm.required': 'Password Confirmation cannot be empty',
    'user.username.required': 'Username cannot be empty',
    'user.phone_number.required': 'Telephone cannot be empty',
    'user.company.required': 'Company name cannot be empty',
    'user.agree.valid': 'Account can only be created after agreeing',
    'user.username.valid': 'Only supports numbers, letters, and underscores',
    'user.email.valid': 'Please input the correct email address',
    'user.password_confirm.valid': 'The two passwords are inconsistent',
    'user.phone_number.valid': 'The phone number is incorrect, please check and try again',
    'user.password.valid1': 'Password length is 8~255 characters',
    'user.password.valid2': 'Password contains at least 1 English letter',
    'user.password.valid3': 'Password contains at least 1 number',
    'user.password.valid4': 'Password contains at least 1 special character（~!@#$%^&*(){}|:<>?[];\',./）',
    'user.password.valid5': 'Password can only contain English letters, numbers and special symbols',
    'user.password.tip_title': 'Security password prompt:',
    'user.password.tip1': 'Password length is 8~255 characters',
    'user.password.tip2': 'Contain at least 1 English letter and 1 number.',
    'user.register_agreement': 'The Byzer team has the right to maintain and clean up the trial environment. Please download and save important files in time.',
    'user.create_account': 'Create an account',
    'user.account_tip': 'Already have an account? Click here ',
    'activation.title': 'Please check email!',
    'activation.text1': 'Thank you for applying to use Byzer Notebook. Please go to the email address you provided to check the verification email. After the verification is passed, we will guide you to play Byzer.',
    'activation.text2': 'If you have not received the email, please check your spam or \'other\' inbox.',
    'activation.actived': 'Your account has been activated successfully, and you will automatically be redirected to the trial page later.',
    'activation.sended': 'The verification email has been sent, please wait',
    'activation.resended': 'The message has been resent, please wait',
    'activation.resend': 'Resend verification email.',
    'banner.title': 'Try {byzer} Online',
    'banner.desc1': 'No need to deploy, Byzer Notebook invites you to experience',
    'banner.desc2': ' one-stop data science platform with low barriers to entry',
    'banner.desc3': 'Byzer Notebook provides quick start tutorials and scenario demos to help you experience the magical charm of the Byzer language',
    'banner.integration': 'Faster data integration',
    'banner.integration_desc1': 'Unified platform to connect multiple data sources',
    'banner.integration_desc2': 'End-to-end data cleaning, integration and exploration capabilities',
    'banner.explore': 'More efficient data exploration',
    'banner.explore_desc1': 'Visual workflow for interactive data exploration',
    'banner.explore_desc2': 'Browse big data sets in real time',
    'banner.learning': 'Simpler machine learning',
    'banner.learning_desc1': 'Use SQL-like programming syntax to play with model training and deployment',
    'banner.learning_desc2': 'Built-in ML model helps you gain faster insights',
    'expired.title': 'Link has expired',
    'expired.desc': 'The link has been used or has expired',
    'expired.please': 'Please ',
    'expired.send_email': ' and resend the email',
    'trial.title': 'Byzer Notebook is ready',
    'start_trial': 'Start trial',
    'trial.img_desc1': 'Add Byzer assistant and be invited to the open source community',
    'trial.img_desc2': 'Follow the Byzer official account for more information',
    'trial.img_desc3': 'Contact technical support',
    'maintenance_notice': 'System maintenance announcement, Beijing time: 2022.01.04 14:00-2022.01.05 10:00 In order to further optimize the trial function of the Byzer online platform, we are implementing the website system upgrade and maintenance. The related services are temporarily affected. Sorry for the inconvenience caused to you.',
}
export default en_US
