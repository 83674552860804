import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import { Form, Input, Checkbox, Button, Tooltip } from 'antd';
import { user } from '../../service'
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/Auth'
import SvgIcon from '../../components/SvgIcon';

const formItemLayout = {
  labelCol: {
    xs: { span: 15 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 0 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 15,
      offset: 9,
    },
  },
};
const RegisterForm = () => {
  const intl = useIntl()
  const [form] = Form.useForm();
  const history = useHistory()
  const { dispatch } = useContext(AuthContext);
  const [loading, changeLoading] = useState(false)

  const onFinish = (values) => {
    const {
      name,
      email,
      password,
      telephone,
      company
    } = values
    const params = {
      name,
      email,
      password,
      telephone,
      company
    }
    handleRegister(params)
  };
  const handleRegister = async (params) => {
    try {
      changeLoading(true)
      await user.submitSignUp(params)
      changeLoading(false)
      window.gtag('event', 'register')
      const { name, email } = params
      dispatch({ type: 'LOGIN', payload: { email, username: name } })
      history.push('/activation')
    } catch (e) {
      changeLoading(false)
    }
  }
  const validatePassword = (rule, value, callback) => {
    const length = (value || '').replace(/[^\x00-\xff]/g, 'AA').length
    const reg1 = /.*?[A-Za-z]/.test(value)
    const reg2 = /.*?[0-9]/.test(value)
    // const reg3 = /.*?[~!@#$%^&*(){}|:<>?[\];\',.\/]/.test(value)
    const reg = /^[A-Za-z0-9~!@#$%^&*(){}|:<>?[\];\',.\/]+$/.test(value)
    // const reg = /^(.*?[A-Za-z])(.*?[0-9])[A-Za-z0-9~!@#$%^&*(){}|:<>?[\];\',.\/]+$/.test(value) // 完整正则
    if (!value && !(value || '').trim()) {
      return Promise.reject(intl.formatMessage({id: 'user.password.required'}))
    } else if (length < 8 || length > 255) {
      return Promise.reject(intl.formatMessage({id: 'user.password.valid1'}))
    } else if (!reg1) {
      return Promise.reject(intl.formatMessage({id: 'user.password.valid2'}))
    } else if (!reg2) {
      return Promise.reject(intl.formatMessage({id: 'user.password.valid3'}))
    } else if (!reg) {
      return Promise.reject(intl.formatMessage({id: 'user.password.valid5'}))
    } else {
      return Promise.resolve()
    }
  }
  const validateCompany = (rule, value, callback) => {
    if (!(value || '').trim()) {
      return Promise.reject(intl.formatMessage({id: 'user.company.required'}))
    } else {
      return Promise.resolve()
    }
  }

  const handleTrim = (key) => {
    const value = form.getFieldValue(key)
    form.setFieldsValue({ [key]: (value || '').trim() })
  }
  const tooltipInfo = () => (<div className="tooltip">
    <div className="test">
      <div className="reset_password-page-form-password-tip-title"><FormattedMessage id='user.password.tip_title' /></div>
      {
        passwordTips.map(v => (
          <div key={v} className="reset_password-page-form-password-tip-text"><span className="dot"></span>{v}</div>
        ))
      }
    </div>
  </div>)
  const passwordTips = [
    <FormattedMessage id='user.password.tip1' />,
    <FormattedMessage id='user.password.tip2' />
  ]
  const tipIcon = 'more_info_16'
  const passwordLabel = (
    <span> 
      <span>{intl.formatMessage({id: 'user.password'})}</span>
      <Tooltip color='#fff' trigger="hover" placement="top" title={tooltipInfo}>
        <span><SvgIcon iconClass={tipIcon} /></span>
      </Tooltip>
    </span>
  )

  return (
    <div className="register-wrapper inner-box">
      <Form
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label={intl.formatMessage({id: 'user.username'})}
          rules={[
            { required: true, message: intl.formatMessage({id: 'user.username.required'}) },
            {
              pattern: new RegExp(/^\w+$/), message: intl.formatMessage({id: 'user.username.valid'})
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          validateTrigger="onBlur"
          label={intl.formatMessage({id: 'user.email'})}
          rules={[
            {
              type: 'email',
              message: intl.formatMessage({id: 'user.email.valid'}),
            },
            {
              required: true,
              message: intl.formatMessage({id: 'user.email.required'}),
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label={passwordLabel}
          rules={[
            {
              required: true,
              validator: validatePassword
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="password_confirm"
          label={intl.formatMessage({id: 'user.password_confirm'})}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: intl.formatMessage({id: 'user.password_confirm.required'}),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({id: 'user.password_confirm.valid'})));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        
        <Form.Item
          name="company"
          label={intl.formatMessage({id: 'user.company'})}
          rules={[
            {
              required: true,
              validator: validateCompany,
              message: intl.formatMessage({id: 'user.company.required'}),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="telephone"
          label={intl.formatMessage({id: 'user.phone_number'})}
          validateTrigger="onBlur"
          rules={[
            { required: true,
              message: intl.formatMessage({id: 'user.phone_number.required'}),
            }, 
          ]}
        >
          <Input onBlur={() => handleTrim('telephone')} />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(intl.formatMessage({id:'user.agree.valid'}))),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox className="register-check"><FormattedMessage id='user.register_agreement' /></Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button loading={loading} type="primary" size="large" className="red-btn" htmlType="submit"><FormattedMessage id='user.create_account' /></Button>
          <div className="register-wrapper-gologin"><FormattedMessage id='user.account_tip' /><a href="/login"><FormattedMessage id='login' /></a></div>
        </Form.Item>
      </Form>
    </div>
    );
};

export default RegisterForm
