import React, { useContext } from 'react';
import { Button } from 'antd'
import { AuthContext } from '../../context/Auth';
import groupCode from '../../image/Byzer-code1.png'
import helperCode from '../../image/Byzer-code2.png'
import emailImg from '../../image/email.svg'
import { FormattedMessage } from 'react-intl';

const TailBaize = () => {
  // const [ip, changeIp] = React.useState('')
  const { state } = useContext(AuthContext)

  // const getBaizeAddress = async () => {
  //   try {
  //     const res = await user.getBaizeAddress()
  //     changeIp(`${res.data.ip}/#/login?username=${state.username}`)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // useEffect(() => {
  //   if (state.username) {
  //     getBaizeAddress()
  //   }
  // }, [state.username])
  const byzerNotebookAddress = window.location.origin + '/lab'

  const handleClickTrial = () => {
    window.gtag('event', 'useBaize', {
      username: state.username
    })
  }

  return (
    <div className="trial-page-content">
      <div className="trial-page-content-title"><FormattedMessage id='trial.title' /></div>
      <div className="trial-page-content-btn">
        <Button onClick={handleClickTrial} type="primary" size="large">
          <a href={byzerNotebookAddress} target="_blank" rel="noreferrer"><FormattedMessage id='start_trial' /></a>
        </Button>
      </div>
      <div className="trial-page-content-img">
        <div className="trial-page-content-img-item">
          <div className="trial-page-content-img-item-img">
            <img src={groupCode} alt=""/>
          </div>
          <div className="trial-page-content-img-item-text"><FormattedMessage id='trial.img_desc1' /></div>
        </div>
        <div className="trial-page-content-img-item">
          <div className="trial-page-content-img-item-img">
            <img src={helperCode} alt=""/>
          </div>
          <div className="trial-page-content-img-item-text"><FormattedMessage id='trial.img_desc2' /></div>
        </div>
        <div className="trial-page-content-img-item">
          <a href="mailto:byzer_org@kyligence.io;">
            <div className="trial-page-content-img-item-img email">
              <img src={emailImg} alt=""/>
            </div>
            <div className="trial-page-content-img-item-text"><FormattedMessage id='trial.img_desc3' /></div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TailBaize
