const zh_CN = {
    ok: '确认',
    cancel: '取消',
    blog: '博客',
    tutorial: '文档',
    community: '社区',
    downloads: '下载',
    video: '视频',
    github: 'Github',
    desc: '一门面向 Data 和 AI 的低代码、云原生的开源编程语言',
    free_trial_text: '无需安装部署，在线快速体验 Byzer',
    ease_of_use: '易于使用',
    ease_of_use_desc: `SQL for AI™️，声明式融合命令式语言，SQL-like 语法简单又灵活。单一底层引擎支持分布式操作海量数据，玩转数据于弹指之间。`,
    data_security: '数据安全',
    data_security_desc: `Byzer 语言内置数据安全并高度可扩展。支持表权限、行列级别权限、语言层语法权限和插件化权限控制。可伸缩，易运维，保障企业级资产安全。`,
    download: '下载',
    try_online_guide: '体验站点引导',
    try_online_guide_desc: '通过体验站点，你可以熟悉 Byzer 语法，体验分析工坊等功能',
    join_wechat_group: '加入微信群',
    join_wechat_group_desc: '扫码下方二维码，添加 Byzer 小助手，专业人员及时答疑。',
    aliyun_support: '云原生支持',
    aliyun_support_desc2: '云原生设计，Byzer 引擎支持 K8s 等云上部署，充分利用云上算力和存储。同时也支持 Yarn 等部署模式。',
    load_save_1: '易用而完善的 Web IDE',
    load_save_1_desc:'统一的语言和平台，您可以在 Web IDE 中编辑和管理您的脚本。',
    load_save_2: '高度可扩展',
    load_save_2_desc: '支持 Python、标准库、使用自定义 UDF（Scala/Java）。内置大量算法和特征工程工具，帮助平民分析师更快上手机器学习。',
    load_save_3: '可视化 ETL Workflow',
    load_save_3_desc: '低代码的 workflow 模式助您高效完成端到端的数据科学旅程。',
    free_trial: '在线试用',
    'versionServer': '服务器版',
    'versionVSCode': 'VSCode 桌面版',
    'versionWeb': 'Web IDE 版',
    'code-tips': '扫码下方二维码，添加 Byzer 小助手，专业人员及时答疑。',
    'Byzer_helper': 'Byzer 小助手',
    'Byzer_official': 'Byzer 公众号',
    'support_desktop_title': '下载 Byzer-lang 和 Byzer Notebook',
    'support_desktop_desc_pre': 'Byzer™ ',
    'support_desktop_desc': '不仅提供线上试用环境，还提供了桌面版，服务器版本，以及 Web IDE Byzer Notebook 供用户安装使用。',
    'support_byzer_download': '下载 Byzer-lang',
    'support_desktop_download': '下载桌面版',
    'support_byzer_notebook_download': '下载 Byzer Notebook',
    'record_information1': '@浙 ICP 备 18052520 号',
    'record_information2': '@浙公网安备 33010802009683 号',
    'community_title': '关于 Byzer 社区',
    'community_text': 'Byzer 是一个新兴且充满活力的开源项目，前身为 MLSQL 社区，核心是 Byzer-lang',
    'community_desc1': '* 面向 Data & AI，围绕 Byzer 打造技术生态',
    'community_desc2': '* 旨在释放分析师、工程师、运维人员的生产力',
    'community_desc3': '* 帮助用户以低成本、高效率的方式落地数据平台和完成 AI 工程化',
    'community_welcome': '欢迎数据开发者们加入，共建社区生态。',
    'join_community_title': '欢迎加入 Byzer 社区 Slack 讨论组',
    'join_community_text': '成为我们大家庭的一员，共同重新定义我们今天构建数据和机器学习渠道的方式。',
    'join_community_link': '加入讨论组',
    'login_tips': '请选择登陆站点',
    'register_tips': '请选择注册站点',
    'site_cn': 'CN 站点',
    'site_global': 'Global 站点',
    'site_login_tip': '温馨提示：前 mlsql.tech 官网注册的用户数据目前已经被同步至 CN 站点',
    'language': '语言',
    'login': '登录',
    'logout': '退出',
    'login.account': '用户名或邮箱',
    'login.account_required': '用户名或邮箱不能为空',
    'forget_password': '忘记密码？',
    'account_tip': '如果您还没有 Byzer Notebook 账号， 请点击',
    'account_tip_register': '注册',
    'reset_password': '重置密码',
    'reset_password_tip': '我们会发送一个链接到您的邮箱，点击之后可以重设密码',
    'new_password': '新密码',
    'password_changed': '您的密码已更改',
    'set_password': '请设置您的新密码',
    'send_email': '发送邮件',
    'back_to_login': '返回登录',
    'check_email': '密码重置链接已经发送到您的邮箱，请查收邮件。',
    'user.email': '邮箱',
    'user.password': '密码',
    'user.password_confirm': '确认密码',
    'user.username': '用户名',
    'user.phone_number': '手机号',
    'user.company': '公司名称',
    'user.email.required': '请输入邮箱地址',
    'user.password.required': '请输入密码',
    'user.password_confirm.required': '请再次输入密码',
    'user.username.required': '请输入用户名',
    'user.phone_number.required': '请输入手机号',
    'user.company.required': '请输入公司名称',
    'user.agree.valid': '同意后才能创建账号',
    'user.username.valid': '仅支持数字，字母，下划线',
    'user.email.valid': '请输入正确的邮箱地址',
    'user.password_confirm.valid': '两次密码不一致',
    'user.phone_number.valid': '手机号码不正确，请检查后重试',
    'user.password.valid1': '密码长度为 8~255 个字符',
    'user.password.valid2': '密码至少包含 1 个英文字母',
    'user.password.valid3': '密码至少包含 1 个数字',
    'user.password.valid4': '密码至少包含 1 个特殊字符（~!@#$%^&*(){}|:<>?[];\',./）',
    'user.password.valid5': '密码至少包含一个英文字母和一个数字, 特殊字符仅支持 ~!@#$%^&*(){}|:<>?[];\',./',
    'user.password.tip_title': '安全密码提示：',
    'user.password.tip1': '密码长度为 8~255 个字符。',
    'user.password.tip2': '至少包含 1 个英文字母和 1 个数字。',
    'user.register_agreement': 'Byzer 团队有权对试用环境进行维护和数据清理，对于重要文件请及时下载和保存。',
    'user.create_account': '创建账号',
    'user.account_tip': '已有账号? 点此',
    'activation.title': '请查收邮件！',
    'activation.text1': '感谢您申请使用 Byzer Notebook，请前往您所提供的邮箱地址查收验证邮件。验证通过后，我们将指导您玩转 Byzer。',
    'activation.text2': '若您未收到邮件，请检查垃圾邮件或“其他”收件箱。',
    'activation.actived': '您的账号已激活成功，稍后将自动跳转至试用页面。',
    'activation.sended': '验证邮件已发送，请稍等',
    'activation.resended': '邮件已重新发送，请稍等',
    'activation.resend': '重新发送验证邮件',
    'banner.title': '在线试用 {byzer}',
    'banner.desc1': '无需部署，Byzer Notebook 邀您体验',
    'banner.desc2': '低门槛的一站式数据科学平台',
    'banner.desc3': 'Byzer Notebook 提供快速入门教程和场景化 demo, 助您体验 Byzer 语言的神奇魅力',
    'banner.integration': '更快捷的数据集成',
    'banner.integration_desc1': '统一平台对接多种数据源',
    'banner.integration_desc2': '端到端的数据清理， 整合和探索能力',
    'banner.explore': '更高效的数据探索',
    'banner.explore_desc1': '可视化工作流进行交互式数据探索',
    'banner.explore_desc2': '实时浏览大数据集',
    'banner.learning': '更简单的机器学习',
    'banner.learning_desc1': '使用类 SQL 编程语法玩转模型训练和部署',
    'banner.learning_desc2': '内置 ML 模型助您获得更快的见解',
    'expired.title': '链接已失效',
    'expired.desc': '该链接已被使用或已过期',
    'expired.please': '请',
    'expired.send_email': '后重新发送邮件',
    'trial.title': 'Byzer Notebook 已经准备好了',
    'start_trial': '开始试用',
    'trial.img_desc1': '添加 Byzer 小助手，获邀进开源社区群',
    'trial.img_desc2': '关注 Byzer 公众号，获取更多资讯',
    'trial.img_desc3': '联系技术支持',
    'maintenance_notice': '系统维护公告，北京时间 ： 2022.01.04 14:00 - 2022.01.05 10:00 为进一步优化 Byzer 线上平台试用功能，我们正在实施网站系统升级维护，有关服务暂时受到影响，因本次升级维护给您造成的不便，敬请谅解'
}
export default zh_CN;
