import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import SvgIcon from '../../components/SvgIcon'
import { Menu, Dropdown } from 'antd'
import { getClientWidth } from '../../util'
const HeaderMenu = () => {
  const navs = [
    { text: <FormattedMessage id='downloads'/>, url: 'https://download.byzer.org/' },
    { text: <FormattedMessage id='tutorial'/>, url: 'https://docs.byzer.org' },
    { text: <FormattedMessage id='community'/>, url: 'https://github.com/byzer-org' },
    { text: <FormattedMessage id='blog' />, url: 'https://docs.byzer.org/#/public/blog/zh-cn/' },
    { text: <FormattedMessage id='video' />, url: 'https://space.bilibili.com/22610047/video' }
  ]
  const screenWidth = getClientWidth()

  const menu = (<Menu>
    {
      navs.map((nav) => {
        return (
          <Menu.Item key="nav.text">
            <a target="_blank" rel="noopener noreferrer" href={nav.url}>
              {nav.text}
            </a>
          </Menu.Item>
        )
      })
    }
  </Menu>)

  return (
    <div className="page-header-wrap-left-menu">
      {
        screenWidth > 900 ? (<Fragment>
          {
            navs.map((nav, index) => {
              return (
                <div key={index} className="page-header-wrap-left-menu-item">
                  <a rel="noopener noreferrer" href={ nav.url } target="_blank">{ nav.text }</a>
                </div>
              )
            })
          }
        </Fragment>) : (
          <Dropdown overlay={menu}>
            <span><SvgIcon iconClass="menu" fontSize={22} /></span>
          </Dropdown>
        )
      }
    </div>
  );
}

export default HeaderMenu;
