import React, { Fragment, useState } from 'react'
import Logo from '../../image/Byzer_With_Slogan_Color_v1.0.svg'
import demo1 from '../../image/grammer1.png'
import demo2 from '../../image/grammer2.png'
import demo3 from '../../image/grammer3.png'
import groupCode from '../../image/Byzer-code1.png'
import helperCode from '../../image/Byzer-code2.png'
import { FormattedMessage, useIntl } from "react-intl"
import { Tooltip, Button } from 'antd'
import { getClientWidth } from '../../util'
import SiteModal from '../../components/Modals/SiteModal'
import SvgIcon from '../../components/SvgIcon'

import './index.scss'
const HomePage = () => {
  const PropertyList = [
    { title: 'ease_of_use', text: 'ease_of_use_desc' },
    { title: 'data_security', text: 'data_security_desc' },
    { title: 'aliyun_support', text: 'aliyun_support_desc2' }
  ]
  const GrammerList = [
    { title: 'load_save_2', text: 'load_save_2_desc', img: demo1 },
    { title: 'load_save_1', text: 'load_save_1_desc', img: demo2 },
    { title: 'load_save_3', text: 'load_save_3_desc', img: demo3 }
  ]
  const screenWidth = getClientWidth()

  const [isShow, setIsShow] = useState(false)

  const { formatMessage } = useIntl()

  const handleFreeTrial = () => {
    setIsShow(true);
  }
  const closeModal = () => {
    setIsShow(false);
  }

  const handleJoin = () => {
    window.open('https://join.slack.com/t/byzer-org/shared_invite/zt-10qgl60dg-lX4fFggaHyHB6GtUmer_xw', '_blank')
  }

  const handleConfirm = (site) => {
    window.gtag('event', 'freetrial')
    let href = '';
    if(site === 'cn') {
      href = "https://mlsql.tech/register";
    } else {
      href = "https://byzer.org/register";
    }
    window.location.href = href;
  }

  return (
    <div className="home-page">
      <SiteModal isShow={isShow} handleConfirm={handleConfirm} handleClose={closeModal}></SiteModal>
      <div className="home-page-top-wrapper">
        <div className="home-page-top inner-box">
          <div className="home-page-top-logo">
            <img alt='' src={Logo}/>
          </div>
          <div className="home-page-top-desc">
            <div className="text">
              <span className="home-page-top-desc-text">Byzer™</span>：
              <FormattedMessage id="desc"/>
            </div>
          </div>
          <div className="home-page-top-trial">
            <Button size="large" className="red-btn" onClick={handleFreeTrial}>
              <FormattedMessage id='free_trial'/>
            </Button>
            <div className="text"><FormattedMessage id='free_trial_text' /></div>
          </div>
        </div>
      </div>

      <div className="home-page-center inner-box">
        {/* <div className="home-page-center-banner">
          <HomeBanner />
        </div> */}
        <div className="home-page-center-text">
          {
            PropertyList.map(v => (
              <div className="home-page-center-text-item" key={v.title}>
                <h2><FormattedMessage id={v.title}/></h2>
                <div className="text"><FormattedMessage id={v.text}/></div>
              </div>
            ))
          }
        </div>
        <div className="home-page-center-img">
          {
            GrammerList.map((v, i) => (
              <div className="home-page-center-img-item" key={v.title}>
                {
                  (i !== 1 && screenWidth > 1204) ? (<Fragment>
                    <img alt='' src={v.img} />
                    <div className='home-page-center-img-item-text'>
                      <h2><FormattedMessage id={v.title}/></h2>
                      <div className="text"><FormattedMessage id={v.text}/></div>
                    </div>
                  </Fragment>) : (<Fragment>
                    <div className='home-page-center-img-item-text'>
                      <h2><FormattedMessage id={v.title}/></h2>
                      <div className="text"><FormattedMessage id={v.text}/></div>
                    </div>
                    <img alt='' src={v.img} />
                  </Fragment>)
                }
              </div>
            ))
          }
        </div>
        <div className="byzer-community">
          <a className="byzer-community-title" href="https://github.com/byzer-org" target="_blank" rel="noreferrer">
            <FormattedMessage id="community_title" />
          </a>
          <div className="byzer-community-text">
            <div className="byzer-community-text-item"><FormattedMessage id="community_text" /></div>
            <div className="byzer-community-text-item"><FormattedMessage id="community_desc1" /></div>
            <div className="byzer-community-text-item"><FormattedMessage id="community_desc2" /></div>
            <div className="byzer-community-text-item"><FormattedMessage id="community_desc3" /></div>
            <div className="byzer-community-text-item"><FormattedMessage id="community_welcome" /></div>
          </div>
        </div>
        <div className="byzer-community byzer-community-join-title"><FormattedMessage id="join_community_title" /></div>
        <div className="byzer-community-join">
          <div className="byzer-community-join-img">
            <SvgIcon iconClass="slack-logo" fontSize={30} />
            <span className='logo-title'>slack</span>
          </div>
          <div className="byzer-community-join-content">
            <div className="byzer-community-join-content-text">
              <FormattedMessage id="join_community_text" />
            </div>
            <div className="byzer-community-join-content-link">
              <div className="byzer-community-join-content-link-wrap" onClick={handleJoin}>
                <span className="text">
                  <FormattedMessage id="join_community_link" />
                </span>
                <SvgIcon iconClass="arrow_right" fontSize={14} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-page-download">
        <div className="home-page-download-wrapper inner-box">
          <div className="home-page-download-title"><FormattedMessage id='support_desktop_title' /></div>
          <div className="home-page-download-text">
            <span className="home-page-download-brand"><FormattedMessage id='support_desktop_desc_pre' /></span>
            <FormattedMessage id='support_desktop_desc' />
          </div>
          <div className="home-page-download-download">
            <Tooltip placement="top" title={formatMessage({id: "versionServer"})}>
              <Button>
                <a href="https://download.byzer.org/byzer/" target="_blank" rel="noreferrer"><FormattedMessage id='support_byzer_download' /></a>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title={formatMessage({id: "versionVSCode"})}>
              <Button>
                <a href="https://github.com/byzer-org/byzer-desktop" target="_blank" rel="noreferrer"><FormattedMessage id='support_desktop_download' /></a>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title={formatMessage({id: "versionWeb"})}>
              <Button>
                <a href="https://download.byzer.org/byzer-notebook/" target="_blank" rel="noreferrer"><FormattedMessage id='support_byzer_notebook_download' /></a>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="home-page-footer">
        <div className="footer-wrapper inner-box">
          <div className="home-page-footer-text">

            <div className="home-page-footer-text-item">
              <div className="text"><FormattedMessage id='code-tips' /> </div>
              <div className="home-page-footer-text-item-image_box">
                <div className="img-text">
                  <img alt='' src={groupCode}></img>
                  <div className="img-text_text"><FormattedMessage id='Byzer_helper' /></div>
                </div>
                <div className="img-text">
                  <img alt='' src={helperCode}></img>
                  <div className="img-text_text"><FormattedMessage id='Byzer_official' /></div>
                </div>
              </div>
            </div>
          </div>
          {
            window.location.origin.includes('mlsql.tech') ?
            <div className="home-page-footer-copyright">
              MLSQL Licensed under the Apache License, Version 2.0.
              <a href="http://www.miitbeian.gov.cn/" target="_blank" rel="noreferrer"><FormattedMessage id='record_information1' /></a><br />
              <a target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802009683">
              <FormattedMessage id='record_information2' /></a>
            </div>
            : null
          }
        </div>
      </div>
  </div>)
}
export default HomePage;
