import React from 'react';
import Banner from '../../components/Banner/index'
import './index.scss';
import Trail from './Trail'

const TrialPage = () => {
  return (
    <div className="trial-page">
      <div className="trial-page-text">
        <Banner />
      </div>
      <div className="trial-page-wrapper">
        <Trail />
      </div>
    </div>
  );
};

export default TrialPage
