import React, { useState } from 'react';
import { Modal, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import './index.scss';
import SvgIcon from '../SvgIcon';
import { Button } from 'antd'

const SiteModal = (props) => {
  const { isFromLogin, isShow, handleConfirm, handleClose } = props;
  const title = (<FormattedMessage id={ isFromLogin ? 'login_tips' : 'register_tips'} />)

  const [value, setValue] = useState('cn');

  const handleOk = () => {
    handleConfirm(value);
  }

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Modal 
      title={title}
      visible={isShow}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          <FormattedMessage id="ok" />
        </Button>
      ]}
      >
      <div className="site-modal">
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={'cn'}>
            <span className="radio-icon"><SvgIcon iconClass="China" fontSize={22} /></span>
            <FormattedMessage id="site_cn" />
          </Radio>
          <Radio value={'global'}>
            <span className="radio-icon"><SvgIcon iconClass="language_22" fontSize={22} /></span>
            <FormattedMessage id="site_global" />
          </Radio>
        </Radio.Group>
        {
          isFromLogin ? <div className='login-site-tips'><FormattedMessage id="site_login_tip" /></div> : null
        }
      </div>
    </Modal>);
};

export default SiteModal;
