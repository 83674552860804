import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'antd';
import { user } from '../../service'
import { useHistory } from 'react-router-dom';
import { message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl';
// import SvgIcon from '../../components/SvgIcon'
import './index.scss'
import { UndoOutlined } from '@ant-design/icons';


const Activation = () => {
  const [loading, changeLoading] = useState(false)
  const [restTime, changeTime] = useState(-1)
  const history = useHistory()
  const intl = useIntl()
  // 是否是刚刚进入页面
  useEffect(() => {
    handleGetActivationStatus()
  }, [])

  const changeRestTime = () => {
    const interval = setInterval(() => {
      if (restTime >= -1) {
        changeTime(restTime => restTime - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }

  const handleResendEmail = async () => {
    try {
      changeLoading(true)
      const res = await user.getActivationStatus()
      if (res.data.activation) {
        changeLoading(false)
        message.warning(intl.formatMessage({id: 'activation.actived'}));
        setTimeout(() => {
          history.push('/trial')
        }, 3000)
        return
      } else {
        await user.resendEmail()
        changeLoading(false)
        message.success(intl.formatMessage({id: 'activation.resend'}))
        changeTime(59)
        changeRestTime()
      }
    } catch (e) {
      changeLoading(false)
    }
  }
  const handleGetActivationStatus = async () => {
    try {
      const res = await user.getActivationStatus()
      if (res.data.activation) {
        history.push('/trial')
      }
    } catch (e) {
      console.log(e)
    }
  }
  // const resendIcon = 'resure_16'

  // 初始进入页面： 邮件已发送 + 重新发送按钮
  // 点击过重新发送之后： 邮件已重新发送 + 按钮倒计时
  // 倒计时结束： 重新发送

  return (
    <div className="send-email-page">
      <h1 className="send-email-page-title"><FormattedMessage id='activation.title'/></h1>
      <div className="send-email-page-text"><FormattedMessage id='activation.text1'/></div>
      <div className="send-email-page-text"><FormattedMessage id='activation.text2'/></div>
      <div className="send-email-page-sended">
        <FormattedMessage id='activation.sended'/>
      </div>
      <div className="send-email-page-resend">
        <Button className="red-btn" loading={loading} icon={<UndoOutlined />} disabled={restTime > -1 } onClick={handleResendEmail}>
        {
          restTime >= 0 ? (
            <Fragment><FormattedMessage id='activation.resended'/><span>({restTime}s)</span></Fragment>
          ) : (
            <Fragment><FormattedMessage id='activation.resend'/></Fragment>
          )
        }
        </Button>
      </div>
    </div>
  );
};

export default Activation
