import React from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../../image/Byzer_Default_Color_v1.0.svg';
import { FormattedMessage } from 'react-intl';
import { Menu, Dropdown, Button } from 'antd';
import HeaderMenu from './HeaderMenu';
import UserInfo from './UserInfo'
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

import './index.scss'

const PageHeader = (props) => {
  const { onLocaleChange } = props

  const history = useHistory()

  const handleChangeLang = (lang) => {
    onLocaleChange(lang.key)
  }

  const langMenu = (<Menu onClick={handleChangeLang}>
    <Menu.Item key="zh">中文</Menu.Item>
    <Menu.Item key="en">English</Menu.Item>
  </Menu>)

  const { pathname } = history.location
  const showMenuPathList = ['/home', '/register', '/trial']
  const showLoginPathList = ['/home', '/register', '/trial']

  return (
    <div className="page-header">
      <div className="page-header-wrap">
        <div className="page-header-wrap-left">
          <div className="page-header-wrap-left-logo">
            <a href="/home"><img src={Logo} alt="" /></a>
          </div>
          {
            showMenuPathList.includes(pathname) ? <HeaderMenu /> : null
          }
        </div>
        <div className="page-header-wrap-right">
        <div className="page-header-wrap-right-item">
          <Dropdown overlay={langMenu} placement="bottomLeft">
            <Button type="text">
              <FormattedMessage id="language" /> <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        {
          showLoginPathList.includes(pathname) ? (
          <div className="page-header-wrap-right-item">
            <UserInfo />
          </div>) : null
        }
        </div>
      </div>
    </div>
  );
}

export default withRouter(PageHeader);
